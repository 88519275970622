import React from "react"
import PropTypes from "prop-types"

/**
 * The primary use case for this component is to wrap custom interactive
 * elements in a button element to make them keyboard accessible without
 * styling them to look like a button.
 */
const InvisibleButton = ({ as: Component = "button", ...props }) => (
  <Component
    css={{
      appearance: "none",
      padding: 0,
      margin: 0,
      background: "transparent",
      border: 0,
      fontSize: "inherit",
      fontWeight: "inherit",
      color: "inherit",
      cursor: "pointer",
      textAlign: "inherit",
      lineHeight: "inherit",
    }}
    {...props}
  />
)

export default InvisibleButton

InvisibleButton.propTypes = {
  as: PropTypes.elementType,
}
