import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const AutoLink = ({ href, to, target, ...rest }) => {
  const url = to || href

  return target || !url.startsWith("/") || /\.[a-z0-9]{1,5}$/.test(url) ? (
    <a href={url} target="_blank" rel="noopener noreferrer" {...rest} />
  ) : (
    <Link to={url} {...rest} />
  )
}

export default AutoLink

AutoLink.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
}
