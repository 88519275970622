import React from "react"
import PropTypes from "prop-types"
import AutoLink from "components/AutoLink"
import InvisibleButton from "components/InvisibleButton"
import theme from "theme"

const Button = props => {
  const ButtonComponent = props.href ? AutoLink : InvisibleButton
  return (
    <ButtonComponent
      {...props}
      css={{
        ...theme.h3,
        fontWeight: 800,
        background: theme.stampRed,
        color: theme.tan,
        padding: "20px 30px",
        lineHeight: 1,
        letterSpacing: "0.1em",
        [theme.mobile]: {
          padding: "15px 30px",
        },
      }}
    />
  )
}

export default Button

Button.propTypes = {
  href: PropTypes.string,
}
