import buildTheme from "build-theme"
import normalize from "emotion-normalize"

const colors = {
  /**
   * @hex #002F3B
   */
  blueGreen: "#002F3B",

  /**
   * @hex #382F2C; used as black
   * @alias theme.black
   */
  greyBrown: "#382F2C",

  /**
   * @hex #4C86A0
   */
  indicaBlue: "#4C86A0",

  /**
   * @hex #789B4A
   */
  lightGreen: "#789B4A",

  /**
   * @hex #D44227
   */
  redOrange: "#D44227",

  /**
   * @hex #E57E3B
   */
  sativaOrange: "#E57E3B",

  /**
   * @hex #A32B2A
   */
  stampRed: "#A32B2A",

  /**
   * @hex #F2E4B1
   */
  tan: "#F2E4B1",

  /**
   * @hex #F7EFDA
   */
  tanLight: "#F7EFDA",

  /**
   * @hex #E3D291
   */
  tanDark: "#E3D291",

  /**
   * @hex #F7F7F6
   */
  gray1: "#F7F7F6",

  /**
   * @hex #C1C0BF
   */
  gray2: "#C1C0BF",

  // For convenience…
  /**
   * @hex #fff
   */
  white: "#fff",

  /**
   * @hex #382F2C
   * @alias theme.greyBrown
   */
  black: "#382F2C",
}

const mq = {
  max: v => `@media (max-width: ${typeof v === "number" ? `${v}px` : v})`,
}

const breakpoints = {
  ...mq,

  /**
   * Media Query: (max-width: 1050px)
   */
  desktopSmall: mq.max(1050),

  /**
   * Media Query: (max-width: 960px)
   */
  mobile: mq.max(960),

  /**
   * Media Query: (max-width: 768px)
   */
  mobileSmall: mq.max(768),

  /**
   * For very smol viewports ≤ 370px wide.
   */
  smol: mq.max(370),
}

const { vars, tokens } = buildTheme({
  styles: {
    h1: {
      fontSize: [44, 26, 28],
      lineHeight: 1.12,
      fontWeight: 900,
      letterSpacing: "0.18em",
      textTransform: "uppercase",
      fontFamily: "var(--displayFont)",
    },
    h2: {
      fontSize: [36, 24, 20],
      lineHeight: 1.12,
      fontWeight: 900,
      letterSpacing: "0.18em",
      textTransform: "uppercase",
      fontFamily: "var(--displayFont)",
    },
    h3: {
      fontSize: [20, 15],
      lineHeight: 1,
      fontWeight: 900,
      letterSpacing: "0.18em",
      textTransform: "uppercase",
      fontFamily: "var(--displayFont)",
    },
    h4: {
      fontSize: [16, 13],
      lineHeight: 1.1,
      fontWeight: 900,
      letterSpacing: "0.18em",
      textTransform: "uppercase",
      fontFamily: "var(--displayFont)",
    },
    h5: {
      fontSize: [12, 9],
      lineHeight: 1.2,
      fontWeight: 800,
      letterSpacing: "0.15em",
      textTransform: "uppercase",
      fontFamily: "var(--displayFont)",
    },
    t1: {
      fontSize: [24, 19],
      lineHeight: 1.22,
      fontWeight: 500,
    },
    t2: {
      fontSize: [18, 16],
      lineHeight: 1.22,
      fontWeight: 500,
      letterSpacing: "-0.01em",
    },
    detail: {
      fontSize: [15, 12],
      lineHeight: 1.2,
      fontWeight: 500,
      letterSpacing: "0.13em",
      textTransform: "uppercase",
    },
  },
  containers: ["desktop", "mobile", "smol"],
})

export default {
  /**
   * Stubs for type tokens; these will be overridden below when `tokens` is
   * expanded, but Intellisense can’t infer them correctly, hence these stubs
   * for auto-completion.
   */

  /**
   * Styles for H1 headings
   */
  h1: {},

  /**
   * Styles for H2 headings
   */
  h2: {},

  /**
   * Styles for H3 headings
   */
  h3: {},

  /**
   * Styles for H4 headings
   */
  h4: {},

  /**
   * Styles for H5 headings
   */
  h5: {},

  /**
   * Styles for large body copy
   */
  t1: {},

  /**
   * Styles for standard body copy
   */
  t2: {},

  /**
   * Styles for small detail text
   */
  detail: {},

  ...colors,
  ...breakpoints,
  ...tokens,
}

export const globalStyles = [
  normalize,
  {
    ":root": {
      "--displayFont": "sweet-sans-pro, sans-serif",
      "--bodyFont": "freight-macro-pro, georgia, serif",
      "--vh": "1vh", // for working around Safari vh bug
      ...vars.desktop,
      [breakpoints.mobile]: vars.mobile,
      [breakpoints.smol]: vars.smol,
    },
  },
  {
    [["*", "*:before", "*:after"]]: {
      boxSizing: "inherit",
    },

    html: {
      ...tokens.t2,
      fontFamily: "var(--bodyFont)",
      color: colors.black,
      background: colors.tanLight,
      wordBreak: "break-word",
      boxSizing: "border-box",

      // Improve browser font rendering
      textRendering: "optimizeLegibility",
      fontFeatureSettings: "'liga', 'kern' 1",
      fontKerning: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
    },

    [["img", "svg"]]: {
      display: "block",
      maxWidth: "100%",
    },

    video: {
      display: "block",
    },

    a: {
      textDecoration: "none",
      color: "inherit",
    },

    [["h1", "h2", "h3", "h4", "h5", "h6"]]: {
      margin: 0,
    },

    pre: {
      maxWidth: "100%",
      overflowX: "auto",
    },
  },
]
