import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"
import { imageUrl, parseImageRef } from "gatsby-plugin-sanity-image"

const PageMetadata = ({ title, description, image }) => {
  const {
    defaults: { defaultMetaImage },
  } = useStaticQuery(graphql`
    {
      defaults: sanitySettings {
        defaultMetaImage {
          asset {
            _id
          }
        }
      }
    }
  `)

  image = image || defaultMetaImage

  return (
    <Helmet title={title}>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />

      {image && ogImageTags(image)}
    </Helmet>
  )
}

export default PageMetadata

PageMetadata.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.shape({
    asset: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

const ogImageTags = image => {
  const url = imageUrl(image, { width: 1200, quality: 70, fit: "max" })
  const { width, height } = computeImageDimensions(image, { width: 1200 })

  return [
    <meta key="url" property="og:image" content={url} />,
    <meta key="width" property="og:image:width" content={width} />,
    <meta key="height" property="og:image:height" content={height} />,
  ]
}

export const query = graphql`
  fragment PageMetadata on PageType {
    metadata {
      ...Metadata
    }
  }

  fragment Metadata on SanityPageMetadata {
    title
    description
    image {
      asset {
        _id
      }
    }
  }
`

/**
 * This routine attempts to compute the final dimensions of a Sanity
 * image asset that might have dimensional constraints or crop
 * parameters applied to it.
 *
 * @copyright © Corey Ward 2020, all rights reserved.
 *
 * @param {object} image Image object with asset and crop properties
 * @param {object} image.asset
 * @param {string} image.asset._id The Sanity ID for the Image
 * @param {object} image.crop
 * @param {object} targetDimensions
 * @param {number} targetDimensions.width Target width of the image
 * @param {number} targetDimensions.height Target height of the image
 * @returns {object} Calculated width and height
 */
const computeImageDimensions = (image, { width, height }) => {
  const { dimensions } = parseImageRef(image.asset._id)
  const origRatio = dimensions.width / dimensions.height
  let cropRatio = origRatio
  let maxWidth = dimensions.width
  let maxHeight = dimensions.height

  if (width && height) {
    // All set, no changes needed
  } else if (!width && !height) {
    // If neither height or width are set, fall back to default
    width = dimensions.width
    height = dimensions.height
  } else {
    if (width) {
      // If width is set, compute height based on aspect ratio
      height = height || Math.round(width / origRatio)
    } else {
      // If height is set, compute width based on aspect ratio
      width = Math.round(height * origRatio)
    }
  }

  // Compensate for dimensional changes if image was cropped in Sanity
  if (image.crop && Object.values(image.crop).some(n => n > 0)) {
    const cropWidth =
      dimensions.width -
      image.crop.left * dimensions.width -
      image.crop.right * dimensions.width
    const cropHeight =
      dimensions.height -
      image.crop.top * dimensions.height -
      image.crop.bottom * dimensions.height

    cropRatio = cropWidth / cropHeight
    if (cropRatio > origRatio) {
      maxHeight = cropHeight
    } else {
      maxWidth = cropWidth
    }
  }

  return {
    width: Math.min(width, maxWidth),
    height: Math.min(height, maxHeight),
  }
}
