import React from "react"
import PropTypes from "prop-types"
import { Global } from "@emotion/react"
import { globalStyles } from "theme"
import AgeGate from "components/AgeGate"
import PageMetadata from "components/PageMetadata"

const PageWrapper = ({ data = {}, children }) => {
  const metadata = Object.values(data).find(entry => entry?.metadata)?.metadata

  return (
    <>
      <Global styles={globalStyles} />
      {/* <AgeGate /> */}
      {metadata && <PageMetadata {...metadata} />}

      {children}
    </>
  )
}

export default PageWrapper

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    metadata: PropTypes.shape(PageMetadata.propTypes),
  }),
}
