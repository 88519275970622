import React from "react"
import PageWrapper from "components/PageWrapper"

export const wrapPageElement = ({ element, props }) =>
  React.createElement(PageWrapper, props, element)

export const onInitialClientRender = () => {
  if (!("scrollBehavior" in document.documentElement.style)) {
    import("scroll-behavior-polyfill")
  }

  // Set vh to match actual viewport height on iOS and Android. All other
  // browsers fall back to `1vh` per var(--vh) and avoid the additional
  // computation.
  if (
    /iPad|iPhone|iPod|Android/i.test(navigator.userAgent) &&
    !window.MSStream
  ) {
    setVh()
    window.addEventListener("load", setVh)
    window.addEventListener("resize", setVh)
  }
}

/**
 * Update CSS vh unit to match *actual* viewport height even in Safari. See
 * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ for more info.
 */
const setVh = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--vh", `${vh}px`)
}
